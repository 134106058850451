import React from 'react'
import { graphql } from 'gatsby'

import { Footer, ControlPanel, Layout } from 'components'
import { WebSocketsProvider, ADAContext } from 'context'

import { formatCollections, stringToArray, extractData } from 'utils'
import { ADALayout } from './layouts/ADAlayout'
import { NonADALayout } from './layouts/NonADALayout'

const CollectionTemplate = ({ data, pageContext }) => {
	const shelf = pageContext.collection_id
	const id = parseInt(pageContext.collection_id)
	const totalCollections = data.allCollectionsCsv.totalCount

	const exploreButtonConfig = {
		type: 'collection',
		text: 'Explore'
	}

	const collections = formatCollections(
		stringToArray(pageContext.collection_names),
		stringToArray(pageContext.collection_ids),
		extractData(data.allImageSharp),
		exploreButtonConfig,
		shelf
	)

	return (
		<WebSocketsProvider enableTimer>
			<ControlPanel />
			<Layout id={id} title='shelf' inline>
				<ADAContext.Consumer>
					{({ ADA }) => (
						<>
							{ADA ? (
								<ADALayout
									id={id}
									collections={collections}
									totalCollections={totalCollections}
								/>
							) : (
								<NonADALayout
									id={id}
									collections={collections}
									totalCollections={totalCollections}
								/>
							)}
							<Footer product={`Shelf #${id}`} trademark={!ADA} ADA />
						</>
					)}
				</ADAContext.Consumer>
			</Layout>
		</WebSocketsProvider>
	)
}

export const query = graphql`
	query InlineCollection($assetName: String) {
		allCollectionsCsv {
			totalCount
		}
		allImageSharp(filter: { fluid: { originalName: { glob: $assetName } } }) {
			edges {
				node {
					gatsbyImageData(width: 450)
					fluid {
						originalName
					}
				}
			}
		}
	}
`

export default CollectionTemplate
