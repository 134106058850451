import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Title, Navigator, Button } from 'components'

import * as styles from './shelf.module.scss'

export const NonADALayout = ({ id, collections, totalCollections }) => {
	return (
		<div className={styles.container}>
			<Title h1>Tap & Explore</Title>
			<Navigator id={id} totalCollections={totalCollections}></Navigator>
			<div className={styles.collectionsContainer}>
				{collections.map((collection, i, collections) => (
					<>
						<div key={i} className={styles.collection}>
							<div className={styles.image}>
								<GatsbyImage
									image={collection.image?.gatsbyImageData}
									alt={`${collection.name} picture`}
								/>
							</div>
							<div className={styles.text}>
								<p className={styles.name}>{collection.name}</p>
								<p className={styles.subtitle}>Collection</p>
								<Button {...collection.buttonConfig} />
							</div>
						</div>
						{i !== collections.length - 1 && (
							<div className={styles.border}></div>
						)}
					</>
				))}
			</div>
		</div>
	)
}
