import React from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import { analyticsSend } from 'utils'
import { MainLogo, Trademark, TapAndExplore, Navigator } from 'components'

import * as styles from './shelf.module.scss'

export const ADALayout = ({ id, collections, totalCollections }) => {
	const onClickHandler = (i, name) => {
		navigate(collections[i].buttonConfig.url)
		analyticsSend({ type: 'track', button: 'Open Collection', product: name })
	}

	return (
		<div className={styles.adaContainer}>
			<div className={styles.adaLogoContainer}>
				<MainLogo />
			</div>
			<Trademark />
			<TapAndExplore inlineADA />
			<Navigator ADA id={id} totalCollections={totalCollections} />
			<div className={styles.carouselContainer}>
				{collections.map((collection, i) => (
					<div
						className={styles.circleContainer}
						key={i}
						onClick={() => onClickHandler(i, collection.name)}
					>
						<div className={styles.circle}>
							<GatsbyImage
								image={collection.image.gatsbyImageData}
								alt={collection.name}
							/>
						</div>
						<p className={styles.adaLabel}>{collection.name}</p>
						<p className={styles.adaSpan}>Collection</p>
					</div>
				))}
			</div>
		</div>
	)
}

ADALayout.propTypes = {
	id: PropTypes.number.isRequired,
	collections: PropTypes.array.isRequired,
	totalCollections: PropTypes.number.isRequired
}
