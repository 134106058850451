// extracted by mini-css-extract-plugin
export var adaContainer = "shelf-module--ada-container--cFC-V";
export var adaLabel = "shelf-module--ada-label--EOEWm";
export var adaLogoContainer = "shelf-module--ada-logo-container--A5H50";
export var adaSpan = "shelf-module--ada-span--vzDSG";
export var border = "shelf-module--border--+Lc4B";
export var carouselContainer = "shelf-module--carousel-container--s5c+b";
export var circle = "shelf-module--circle--2WvAR";
export var circleContainer = "shelf-module--circle-container--R1mu7";
export var collection = "shelf-module--collection--XE+g9";
export var collectionsContainer = "shelf-module--collections-container--FQcPa";
export var container = "shelf-module--container--pE8k0";
export var image = "shelf-module--image--9YUyQ";
export var name = "shelf-module--name--Y8g8I";
export var registered = "shelf-module--registered--+hEh7";
export var subtitle = "shelf-module--subtitle--eAq3I";
export var text = "shelf-module--text--utuzX";